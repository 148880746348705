import { PrimaryButton, Stack } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  SDropdownStyle,
  STextAreaFieldStyle,
  STextFieldStyle,
} from '../../styles';
import { CountryPicker, StatePicker, TextField } from '../Form';

const addressSchema = yupResolver(
  yup.object().shape({
    address_line: yup
      .string()
      .trim()
      .max(100, 'Maximum 200 characters are allowed')
      .required('Address is required'),
    state: yup.string().trim().required('State is required'),
    country: yup.string().trim().required('Country is required'),
  }),
);

export interface IAddressFormValues {
  address_line: string;
  state: string;
  country: string;
  landmark: string;
  pin_code: string;
}

interface IAddressFormProps {
  onSubmit: (values: any) => void;
  address?: IAddressFormValues;
}

export const AddressForm = ({ onSubmit, address }: IAddressFormProps) => {
  const addressForm = useForm<IAddressFormValues>({
    mode: 'all',
    resolver: addressSchema,
    defaultValues: {
      address_line: '',
      landmark: '',
      country: '',
      pin_code: '',
      state: '',
    },
  });

  useEffect(() => {
    if (address) {
      addressForm.reset(address);
    }
  }, [address]);

  const { handleSubmit } = addressForm;
  const country = addressForm.watch('country');
  return (
    <Stack>
      <Stack.Item>
        <Stack.Item grow>
          <TextField
            required
            formHook={addressForm}
            name="address_line"
            label="Address"
            styles={STextAreaFieldStyle}
            multiline
            placeholder="Enter your address"
          />
        </Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <Stack.Item
            styles={{
              root: {
                width: '100%',
              },
            }}
          >
            <CountryPicker
              required
              formHook={addressForm}
              name="country"
              label="Country"
              styles={SDropdownStyle}
              placeholder="Select your country"
            />
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                width: '100%',
              },
            }}
          >
            <StatePicker
              required
              formHook={addressForm}
              name="state"
              label="State"
              styles={SDropdownStyle}
              countryCode={country}
              disabled={!country}
              placeholder="Select your state"
            />
          </Stack.Item>
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <Stack.Item grow>
            <TextField
              required
              formHook={addressForm}
              placeholder="Enter your city"
              name="landmark"
              label="City"
              styles={STextFieldStyle}
            />
          </Stack.Item>
          <Stack.Item grow>
            <TextField
              required
              formHook={addressForm}
              placeholder="Enter your pin code"
              name="pin_code"
              label="Pin code"
              styles={STextFieldStyle}
            />
          </Stack.Item>
        </Stack>
        <Stack.Item grow align="center" className="flex justify-center pt-5">
          <PrimaryButton onClick={handleSubmit(onSubmit)} text="Save" />
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
